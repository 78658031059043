@import "../config/variables";

@mixin clearfix() {
	&:before,
	&:after {
		content: " "; /* 1 */
		display: table; /* 2 */
	}
	&:after {
		clear: both;
	}
}

@mixin ir() {
	border: 0;
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

.ir {
	@include ir();
}

.clearfix {
	@include clearfix();
}
