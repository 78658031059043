.blogpost-banner{
    height:0;
    padding-bottom:50%;
    position: relative;

    & > div{
        position: absolute;
        top:0;
        bottom:0;
        left:25px;
        right:25px;
        height:100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}
.postcontent{
    image{
        width:100% !important;
        height:auto !important;
    }
}