@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";


// Latest news
.latest-news{
    background-color: $gray-bg;

    &.newslist{
        background-color: #fff !important;
    }

    h2{
        margin-bottom:60px !important;
    }

    article{
        
        &:after{
            content:'';
            display:block;
            height:1px;
            background-color:#eee;
            width:100%;
            margin-top:30px;
            margin-bottom:40px;
        }

        a{
            span{
                display:inline;
                position: relative;
                &:after{
                    content:'';
                    position: absolute;
                    left:0;
                    width:0;
                    bottom:0;
                    height:1px;
                    background-color: $blue;
                    transition: all 250ms ease-in-out;
                }
            }
            &:hover, &:focus, &:active{
                text-decoration: none;
            }
            &:hover{
                span{
                    &:after{
                        width:100%;
                    }
                }
            }
            
            p{
                color:$text-color;
            }

            .news-date{
                color:#bbb;
                font-size:15px;
                letter-spacing: 0.05em;
            }
        }

        
    }

    
}