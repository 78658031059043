@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";

// Navigation
nav.navbar.navbar-default{
    background-color: #fff;
    border-radius:0;
    border: none; // resetting
    border-bottom: 1px solid #eee;
    margin-bottom:0;

    .logo{
        width:116px;
    }

    .container-fluid{
        position: relative;

        .navbar-header, .lang-nav{
            position: relative;
            z-index:2;
        }
        .navbar-collapse{
            z-index:1;
        }

        .navbar-brand{
            padding:25px 15px 10px 15px;
            height:auto;
        }

        .navbar-collapse{
            text-align: center;
            @media screen and (min-width:768px){
                position: absolute;
                left:0;
                width:100%;
            }

            .nav.navbar-nav {

                & > li{

                    & > a{
                        color: $text-color;
                        font-size:14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        position: relative;
                        letter-spacing: 0.05em;

                        &:after{
                            content:'';
                            position: absolute;
                            display:block;
                            height:2px;
                            width:0;
                            background-color: $blue;
                            bottom:10px;
                            left:50%;
                            transform: translateX(-50%);
                            transition: width 250ms ease-in-out;

                        }
                    }                    
                }                
            }

            @media screen and (max-width:767px){
                .nav.navbar-nav{

                    & > .current{

                        & > a{
                            font-weight: 400;
                        }
                    }
                }
            }
    
            @media screen and (min-width:768px){
                .nav.navbar-nav {
                    float: none;
                    margin: 0 auto;
                    display: inline-block;
                    padding-top: 15px;
                    padding-bottom: 10px;

                    & > li{

                        & > a{

                            &:hover:after{
                                width:30%;
                            }
                        }

                        & > span{
                            color: $text-color;
                            font-size:15px;
                            text-transform: uppercase;
                            font-weight: 400;
                            position: relative;
                            letter-spacing: 0.05em;
                            display:block;
                            padding:15px 0;
                            line-height:20px;
                        }
                    }

                    & > .current{

                        & > a{
                            background-color: transparent;
                            color:$text-color;
    
                            &:after{
                                width:30%;
                            }
                            &:hover:after{
    
                            }
                        }
                    }                   
                }               
            }

            @media screen and (max-width:841px){
                .nav.navbar-nav {
                    li{
                        a{
                            padding-left:10px;
                            padding-right:10px;
                        }
                    }
                }
            }
        }

       

        .lang-nav{
            display:inline-block;
            float:right;
            width:131px;
            @media screen and (max-width:841px){
                width:auto;
            }

            .lang{
                padding:0;
                margin:0;
                position: relative;
                overflow: auto;
                list-style: none !important;
                padding-top: 15px;
                padding-bottom: 10px;
                text-align: right;

                & > li{
                    display:inline-block;


                    & > a{
                        color: $text-color;
                        font-size:14px;
                        text-transform: uppercase;
                        font-weight: 500;
                        position: relative;
                        letter-spacing: 0.05em;
                        padding:15px 8px;
                        display:block;

                        img{
                            width:21px;
        
                            
                        }
                    }
                }
                

            }
        }
        @media screen and (min-width:768px){
            .desktop{
                display:inline-block;
            }
            .mobile{
                display:none;
            }
        }
        @media screen and (max-width:767px){
            .desktop{
                display:none;
            }
            .mobile{
                display:inline-block;
            }
            
        }
    }

    @media screen and (max-width:767px){
        .container-fluid{
            .navbar-brand{
                padding:10px 15px;
            }

            .mobile{

                & > .lang{
                    padding:0;
                    padding-right:25px;

                    & > li{

                        & > a{
                            padding-left:10px;
                            padding-right:10px;
                            padding-top:15px;
                        }
                    }
                }
                
            }
        }
    }
    @media screen and (max-width:380px){
        .container-fluid{
            padding-left:0;
            padding-right:0;

            .navbar-brand{
                img{
                    margin-left:15px;
                }
            }
            

            .mobile{

                & > .lang{

                    padding-right:0px;

                    & > li{

                        & > a{
                            padding-left:3px;
                            padding-right:3px;
                        }
                    }
                }
                
            }
        }
    }

    $speed: 200ms;
$easing: cubic-bezier(0.7, 0, 0, 0.7);

.navbar-toggle {
  overflow: hidden;
  background-color: transparent;
  border:none;
  margin-top:9px;
  margin-bottom:0;

  .icon-bar {
    transition: opacity, transform;
    transition-duration: $speed;
    transition-timing-function: $easing;
    background-color:#333;
  }

  &:not(.collapsed) {
    .icon-bar {
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translateX(-100%);
      }
      &:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
}
    
    
}