@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";

#instafeed{

    .ig-image{
        width:25%;
        display:inline-block;
        height:0;
        padding-bottom:25%;

        @media screen and (max-width:767px){
            width:50%;
            padding-bottom:50%;
        }

        &:hover:after{
            width:0 !important;
        }

        & > div{

            position: absolute;
            left:10px;
            right:10px;
            bottom:10px;
            top:10px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size:cover;

            & > img{
                height:100%;
            }
        }
    }
}