@import "../config/variables";

.publications{
    background-color: #fff;

    .collapsed{
        // display:none;
        // padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms ease-out;
        font-size:14px;
    }

    .btn-summary{
        color: $blue;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: 400;
        font-size:14px;
        display:inline-block;
        cursor: pointer;
        margin:0;
        padding-top:15px;
        padding-bottom:15px;

        &.closeSummary{
            display:none;
        }
    }

    .row{

    
        article{

            @media screen and (min-width:768px){
                &:before{
                    content:'';
                    width:42px;
                    height:42px;
                    display:block;
                    background: #fff url('../images/book-temp.png') no-repeat center center;
                    background-size:cover;
                    position: absolute;
                    top:0;
                    left:-55px;
                }
            }
            

            &:after{
                margin-top:15px;
            }

            &:last-of-type{

                &:after{
                    background-color: transparent !important;
                    margin:0;
                }
            }
        }
    }
}