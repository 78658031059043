@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";
@import "../components/animations";

body{
    overflow-y: scroll;
}
html, body{
    font-family: 'Montserrat', sans-serif;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
   -webkit-overflow-scrolling: touch;

    #main{

        font-weight: 300;
        font-size:18px;
        line-height: 27px;


        h2{
            margin-top:0;
            margin-bottom:30px;
            font-size:38px;
            font-weight: 600;
        }
        h3{
            font-size: 27px;
            line-height:1.5;
            font-weight: 600;
        }
        h4{
            font-size:21px;
            font-weight: 600;
        }

        // Blue uppercase heading
        h6{
            color: $blue;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            font-weight: 500;
            margin-top:0;
            font-size:16px;
        }
        @media screen and (max-width:767px){
            h2{
                font-size:30px;
                margin-bottom:25px;
            }
            h3{
                font-size:22px;
            }
            h3{
                font-size:18px;
            }

        }
        a:not(.btn-blue){
            color: $blue;
            position: relative;
    
            &:after{
                content:'';
                position: absolute;
                left:0;
                width:0;
                bottom:0;
                height:1px;
                background-color: $blue;
                transition: all 250ms ease-in-out;
            }
            &:hover:after{
                width:100%;
            }
    
            &:hover, &:focus, &:active{
                text-decoration: none;
                color: $blue;
            }
        }
    
        // Global
        .text-center{
            text-align: center;
        }
    
        .p-t-100{
            padding-top:100px;
        }
        .p-b-100{
            padding-bottom:100px;
        }
        .p-b-50{
            padding-bottom:50px;
        }
        @media screen and (max-width:767px){
            .p-t-100{
                padding-top:80px;
            }
            .p-b-100{
                padding-bottom:80px;
            }

        }
    
        .btn-blue{
            color:#fff;
            text-transform: uppercase;
            background-color: $blue;
            display:inline-block;
            padding:10px 20px;
            font-size:13px;
            font-weight: 500;
            letter-spacing: 0.05em;
            transition: background-color 250ms ease-in-out;
    
            &:hover{
                background-color: $black;
            }
            &:hover, &:focus, &:active{
                text-decoration: none;
                outline: none;
            }
    
        }
    } 

    
    
}

#cookie_container{
    background-color:#333 !important;
    a{
        background-color:$blue !important;
        color:#fff !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        letter-spacing: 0.05em !important;
        text-transform: uppercase;
        transition: all 250ms ease-in-out;

        &:hover, &:focus, &:active{
            background-color:#666 !important;
            color:#fff !important;
        }
    }
    p{
        color:#fff !important;
    }
}