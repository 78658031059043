@import "node_modules/normalize-scss/sass/normalize/variables";
@import "node_modules/normalize-scss/sass/normalize/vertical-rhythm";
@import "node_modules/normalize-scss/sass/normalize/normalize-mixin";
@include normalize();

@import "../config/variables";

* {
	box-sizing: border-box;
}

html, body {
	margin: 0;
	padding: 0;
	min-height: 100%;
    height: 100%;
	overflow-x: hidden;
}

body {
}
