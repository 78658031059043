@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";

// Features
.features{
    // width:100%;

    .feature{
        padding-bottom:80px;
        
        @media screen and (min-width:992px){
            width:100%;
            display: table;
            

            & > div{
                float: none;
                display: table-cell;
                vertical-align: top;
                position: relative;


                
                    .feature-content{
                        position: absolute;
                        left:0;
                        right:0;
                        width:100%;
                        top:50%;
                        transform: translateY(-50%);
                        padding-left:15px;
                        padding-right:15px;
                    }
                    &.left{
                        & > div{
                            padding-right:30px;
                        }
        
                    }
                    &.right{
                        & > div{
                            padding-left:30px;
        
                            &.feature-content{
                                padding-left:45px;
                                padding-right:0;
                            }
                        }
                    }
                    

                    
                
                
                
            }

        }

        img{
            width:100%;
        }
        p{
            margin-bottom:25px;
        }
        h3{
            margin-top:15px;
            margin-bottom:25px;
        }
        h6{
            margin-bottom:0px;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px){
        // padding-top:50px;
        .feature{
            // padding-bottom:150px;
        }
    }

    @media screen and (max-width:767px){
        img{
            padding-bottom:30px;
        }
    }
}