@import "../config/variables";

.experts{

    .experts-wrapper{
        display:table;
        width:100%;
    }

    .expert{
        position: relative;
        display:inline-table;
        vertical-align: unset;

        @media screen and (min-width:992px){
            width:25%;
        }
        @media screen and (min-width:767px) and (max-width:991px){
            width:50%;
            padding-bottom:40px;
        }
        @media screen and (max-width:767px){
            width:50%;
            padding-bottom:40px;
        }
        
        @media screen and (max-width:400px){
            width:100%;
            padding-bottom:40px;
        }
        

        .expert-inner{
            padding:0 15px;

            .expert-image{
                width:80%;
                height:0;
                padding-bottom:80%;
                position: relative;
                border-radius:500px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                display:inline-block;
                
            }
            .expert-content{
                padding-top:30px;

                p{
                    margin-bottom:10px;

                    & > b{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.addresses{
    background-color: $gray-bg;

    .addresses-wrapper{
        display:table;
        width:100%;
    }

    .address{
        position: relative;
        display:inline-table;
        vertical-align: unset;

        @media screen and (min-width:992px){
            width:33.33%;
        }
        @media screen and (min-width:767px) and (max-width:991px){
            width:50%;
            padding-bottom:40px;
        }
        @media screen and (max-width:767px){
            width:100%;
            padding-bottom:40px;
        }
        

        .address-inner{
            padding:0 15px;

            .address-embed{
                position: relative;
                width:100%;
                height:0;
                padding-bottom:56.25%;

                iframe{
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    height:100%;
                    width:100%;
                }
            }

            .address-content{
                padding-top:30px;

                p{
                    margin-bottom:0px;

                    

                    &.name{
                        margin-bottom:10px;
                        
                        b{
                            font-weight: 600;
                            margin-bottom:10px;
                        }
                    }
                }
            }

        }

    }
}

// .contactform
form{
    fieldset{
        border: none;
        padding:0;
        margin:0;
        label{
            font-weight: 500;
            text-transform: uppercase;
            font-size:14px;
            letter-spacing: 0.05em;
            
        }
        & > .field:not(.textarea){
            width:50%;

            @media screen and (max-width:991px){
                width:100%;
            }
            display:inline-block;
            float:left;
            padding-bottom:25px;


            .middleColumn{
                input{
                    width:100%;
                    padding:10px;
                    border: 1px solid #ddd;
                }
                
            }
            @media screen and (min-width:992px){
                &:nth-child(odd){
                    
                    .middleColumn{
                        padding-right:10px;
                    }
                }
                &:nth-child(even){
                    label{
                        margin-left:10px;
                    }
                    .middleColumn{
                        padding-left:10px;
                    }
                }
            }
        }

        .field.textarea{
            width:100%;
            textarea{
                width:100%;
                padding:10px;
                border: 1px solid #ddd;
            }
        }
        
    }
    .Actions{
        text-align:right;
        padding-top:25px;
        input.btn-blue{
            border:none !important;
        }
    }
    
}