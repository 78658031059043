@import "../config/variables";
@import "../utils/mixins";
@import "../utils/functions";

// Banner
.banner{
    width:100%;
    // height:0;
    // padding-bottom:56.25%;
    height:70vh;
    min-height:400px;
    position: relative;

    .banner-img{
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .banner-content{
        position: absolute;
        width:800px;
        left:50%;
        top:50%;
        text-align: center;
        transform: translate(-50%, -50%);

        @media screen and (max-width:991px){
            left:15px;
            right:15px;
            width:auto;
            transform: translateY(-50%);
        }

        color:#fff;
        @media screen and (max-width:767px){
            left:15px;
            right:15px;
            width:auto;
            transform: translateY(-50%);
        }

        h1{
            letter-spacing: 0em;
            font-size:54px;
            margin-top:0;
            margin-bottom:20px;
            font-weight: 700;
            @media screen and (max-width:767px){
                font-size:35px;
            }
        }
        p{
            font-weight: 300;
            font-size:20px;
            letter-spacing: 0.0em;
        }
    }
}