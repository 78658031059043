$paula-red: #ff1200;

.paula-highlights{


    table{
        width:100%;

        tr{
            width:100%;

            td{
                width: 30%;
                padding-left: 1.66%;
                padding-right: 1.66%;
                display:inline-table;
                vertical-align: unset;

                img{
                    width:50%;
                    height:auto !important;
                }
            }
        }
    }

    .highlights{
        padding-top:50px;

        & > div > div{
            padding-left:15px;
            padding-right:15px;

            img{
                width:96px;
            }
        }

        @media screen and (max-width:767px){
            & > div:not(:last-of-type){
                padding-bottom:50px;
            }
        }
    }
}

.paula-btn{
    background-color: $paula-red !important;
}
.testimonial-banner{
    position: relative;
    height:700px;
    

    & > .testimonial-banner-image{
        background-size:cover;
        background-position: top center;
        background-repeat: no-repeat;

        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;

        height:100%; 
    }

    & > .testimonial-content{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        width:100%;
        left:0;
        right:0;


        p{
            width:700px;
            color:#fff;
            font-weight:500;
            font-style: italic;
            font-size: 25px;
            display:inline-block;
            // margin:0 auto;
            position: relative;
            line-height: 1.2;

            &:before{
                content:'';
                background: transparent url('../images/ic_quote_left.svg') no-repeat center center;
                display:block;
                font-size:50px;
                width:25px;
                height:32px;
                position: absolute;
                top:0;
                left:-50px;
                line-height: 0;
            }
            &:after{
                content:'';
                background: transparent url('../images/ic_quote_right.svg') no-repeat center center;
                display:block;
                width:25px;
                height:32px;
                position: absolute;
                bottom:0;
                right:-50px;
                line-height: 0;
            }

            
        }
    }

    @media screen and (max-width:767px){
        height:400px;

        & > .testimonial-content{
            p{
                width:80%;
                font-size:20px;
            }
        }
    }
}